import { extendTheme } from "@chakra-ui/react"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/rasa/400.css"
import "@fontsource/playfair-display/400.css"
import "@fontsource/playfair-display/500.css"
import "@fontsource/playfair-display/600.css"
import "@fontsource/playfair-display/700.css"
import "@fontsource/playfair-display-sc/400.css"
import "@fontsource/playfair-display-sc/700.css"

const myTheme = {
    fonts: {
        body: "Montserrat",
        heading: "Playfair Display",
    },
    colors: {
        brand: {
            "orange": "#FF8C41",
            "orange-light": "#FFE8D9",
            "white": "#FBF7F5",
            "dark-pink": "#DDA9A2",
            "dark-pink-light": "#F1CEC9",
            "dark-pink-light-alt": "#F2C0BB",
            "light-pink": "#DAC6C4",
            "black": "#2D3748",
            "black-light": "#414B5A",
            "black-extra-light": "#818791",
            "copy": "#2D3748",
            "light-coral": "#FFF0EC",
            "purple": "#e08193",
        },
    },
    components: {
        Heading: {
            baseStyle: {
                fontWeight: "medium",
                color: "brand.black"
            },
            variants: {
                uppercase: {
                    fontFamily: "Playfair Display SC"
                }
            }
        },
        Text: {
            baseStyle: {
                color: "brand.black",
                lineHeight: "175%",
                fontSize: {base: "md", md: "lg"}
            }
        },
        FormLabel: {
            baseStyle: {
                fontWeight: "600"
            },
        },
        Input: {
            variants: {
                filled: {
                    field: {
                        bg: "brand.white",
                        _focus: {
                            bg: "brand.white"
                        }
                    }
                },
            },
            sizes: {
                md: {
                    field: {
                        fontSize: "sm"
                    }
                }
            }
        },
        Button: {
            variants: {
                solid: (props) => ({
                    bg: "brand.orange",
                    textColor: "brand.white",
                    fontWeight: "semibold",
                    _hover: {
                        bg: "orange.500",
                        textColor: "brand.white"
                    },
                    _active: {
                        bg: "orange.500",
                        textColor: "brand.white"
                    }
                }),
                link: (props) => ({
                    textColor: "brand.black",
                    _hover: {
                        textColor: "brand.orange",
                        textDecoration: "none"
                    }
                }),
                underlined: (props) => ({                    
                    padding: 0,
                    height: "auto",
                    lineHeight: "normal",
                    verticalAlign: "baseline",          
                    textColor: "brand.black",
                    textDecoration: "underline",
                    _hover: {
                        textColor: "orange.500",
                        textDecoration: "underline"
                    }
                }),
                black: (props) => ({
                    bg: "brand.black",
                    textColor: "brand.white",
                    _hover: {
                        bg: "brand.orange",
                        textColor: "brand.white"
                    },
                    _active: {
                        bg: "orange.600",
                        textColor: "brand.white"
                    }
                }),
                white: (props) => ({
                    bg: "brand.white",
                    textColor: "brand.dark",
                    _hover: {
                        bg: "brand.orange",
                        textColor: "brand.white"
                    },
                    _active: {
                        bg: "orange.600",
                        textColor: "brand.white"
                    }
                }),
                orange: (props) => ({
                    bg: "brand.orange",
                    textColor: "brand.white",
                    _hover: {
                        bg: "orange.500",
                        textColor: "brand.white"
                    },
                    _active: {
                        bg: "orange.600",
                        textColor: "brand.white"
                    }
                }),
            },
        },
        Link: {
            baseStyle: {
                color: "brand.black",
                fontWeight: "medium",
                textDecoration: "none",
                _hover: { 
                    color: "brand.orange",
                    textDecoration: "none"
                }
            },
            variants: {
                black: {
                    color: "brand.black"
                },
                white: {
                    color: "brand.white"
                }
            }
        },
        List: {
            baseStyle: {
                item: {
                    color: "brand.black",
                    lineHeight: "175%",
                    fontSize: {base: "md", md: "lg"}
                },
                container: {
                    my: 5
                }
            }
        },
        Badge: {
            variants: {
                white: {
                    px: 3,
                    fontWeight: "bold",
                    bgColor: "brand.white",
                    color: "brand.black",
                    textTransform: "capitalize",
                    fontSize: { base: "sm", md: "md", lg: "xl" },
                    rounded: "lg"
                },
                black: {
                    px: 3,
                    fontWeight: "bold",
                    bgColor: "brand.black",
                    color: "brand.white",
                    textTransform: "capitalize",
                    fontSize: { base: "sm", md: "md", lg: "xl" },
                    rounded: "lg"
                }
            }
        }
    },      
};

const theme = extendTheme(myTheme)
export default theme